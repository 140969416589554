import styled from 'styled-components';
import ContextProvider from './context/ContextProvider';
// import './scss/App.scss';
import CustomThemeProvider from './style/CustomThemeProvider';
import { Footer } from './views/components/Footer';
import { PageContent } from './views/components/PageContent';
import { Host } from './views/Host/Host';
import { Player } from './views/Player/Player';
import { DeveloperConsole } from './console/DeveloperConsole';

function App() {

	const path = window.location.pathname;

	return (
		<ContextProvider>
			<CustomThemeProvider>
				<DeveloperConsole/>
				<Container>
					<PageContent>
						{path === '/host' ?
							<Host/> :
							<Player/>
						}
					</PageContent>
					<Footer/>
				</Container>
			</CustomThemeProvider>
		</ContextProvider>
	);
}

// styled components

const Container = styled.div`

	height:100%; width: 100%;

	display: flex;
	flex-direction: column;

`;

export default App;
