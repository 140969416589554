import styled from 'styled-components';
import { Button } from '../components/Button';
import { Title } from '../components/Title';

import thumbnail0 from '../../assets/images/Thumbnail_0.png';
import thumbnail1 from '../../assets/images/Thumbnail_1.png';
import thumbnail2 from '../../assets/images/Thumbnail_2.png';
import thumbnail3 from '../../assets/images/Thumbnail_3.png';
import thumbnail4 from '../../assets/images/Thumbnail_4.png';
import thumbnail5 from '../../assets/images/Thumbnail_5.png';
import thumbnail6 from '../../assets/images/Thumbnail_6.png';
import thumbnail7 from '../../assets/images/Thumbnail_7.png';
import thumbnail8 from '../../assets/images/Thumbnail_8.png';

import Theme from '../../style/theme';
import { useTranslation } from 'react-i18next';



const data = [
	{
		label: 'online',
		thumbnail: thumbnail0,
	},
	{
		label: 'aanmeldformulier',
		thumbnail: thumbnail7,
	},
	{
		label: 'achterindebus',
		thumbnail: thumbnail2,
	},
	{
		label: 'dugout',
		thumbnail: thumbnail1,
	},
	{
		label: 'decornervlag',
		thumbnail: thumbnail3,
	},
	{
		label: 'penalty',
		thumbnail: thumbnail4,
	},
	{
		label: 'kleedkamer',
		thumbnail: thumbnail8,
	},
	{
		label: 'tafelvoetbal',
		thumbnail: thumbnail5,
	},
	{
		label: 'deparkeerplaats',
		thumbnail: thumbnail6,
	},
];

export const Select = () => {
	const {t} = useTranslation();
	
	return (
		<Container id="pc-select" style={{display: 'none'}}>
			<Title>{t('pages.select.title')}</Title>
			<Grid>
				{data.map((value, index) => 
					<Scene key={`scene-button-${index}`} url={value.thumbnail} id={`host-scene-btn-${index}`}>
						<SceneLabel>{`>> ${t(`pages.select.${value.label}_title`)} <<`}</SceneLabel>
					</Scene>
				)}
			</Grid>
			<div style={{display:'flex'}}>
				<Button id="outro-btn" style={{marginBottom: '50px', marginLeft:'auto'}}>{t('general.close')}</Button>
			</div>
		</Container>
	);
};

// styled components

const Container = styled.div`
	margin-top: -20px;
		// Extra small screens
	@media (min-width: ${Theme.responsive.media.xs}){
		margin-inline: ${Theme.responsive.whitespace.xs * 2}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		margin-inline: ${Theme.responsive.whitespace.sm * 2}px;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		margin-inline: ${Theme.responsive.whitespace.lg * 2}px;
	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		margin-inline: ${Theme.responsive.whitespace.xl}px;
	}
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 20px;

	margin-bottom: 50px;
`;

const Scene = styled.button`
	position: relative;
	border: none;
	height: 200px;

	background-image: url(${p => p.url});
	background-size: cover;
	background-position: center;

	cursor: pointer;

	box-shadow: 5px 5px ${Theme.colors.neutralDarker};

	transition: all .4s;

	&:hover > div{
		opacity: 1;
	}
	&:hover{
		transform: translate(-5px,-5px);
		box-shadow: 10px 10px ${Theme.colors.neutralDarker};
	}

	height: 25vh;
	max-height: 50vw;
`;

const SceneLabel = styled.div`
	position: absolute;
	left:0;bottom:0;right:0;
	background-color: ${Theme.colors.neutralDarkest};
	font-family: 'ZillaSlab';
	font-size: 1.5rem;
	color: ${Theme.colors.primary};
	pointer-events: none;
	opacity: 0;
	padding-bottom: 6px;

`;