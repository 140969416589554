import styled from 'styled-components';
import Theme from '../../style/theme';
import { Title } from '../components/Title';
import { useTranslation } from 'react-i18next';

export const Connected = () => {
	const {t} = useTranslation();
	return(
		<Container id={'pc-connected'} className='d-none'>
			<Title>{t('pages.connected.title')}</Title>
			<p>
				{'Je gaat straks verschillende scenario’s zien op het grote scherm. Op een gegeven moment krijg jij de keuze hoe de hoofdpersoon moet reageren. Je hebt hier kort de tijd voor. Wat zou jij doen in elke situatie?'}
			</p>
			<p>{t('pages.connected.connected_to')}<span style={{color: Theme.colors.primary, marginLeft: '5px'}} id={'player-roomcode'}></span></p>
		</Container>
	);
};

// styled components

const Container = styled.div`
	text-align: center;
`;
