export default {

	'general': {

		
	},
	'pages': {

	},
};
