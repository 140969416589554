import watzoujijdoenSound from '../../assets/sounds/watzoujijdoen(trim).wav';
import introSound from '../../assets/sounds/intro.wav';
import outroSound from '../../assets/sounds/outro.wav';

export const Audio = () => {
	return (
		<>
			<audio id="decisions-sound">
				<source src={watzoujijdoenSound} type="audio/wav"/>
			</audio>

			<audio id="intro-sound">
				<source src={introSound} type="audio/wav"/>
			</audio>

			<audio id="outro-sound">
				<source src={outroSound} type="audio/wav"/>
			</audio>
		</>
	);
};