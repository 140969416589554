import styled from 'styled-components';
import Theme from '../../style/theme';


export const Video = () => {
	return (
		<Container id={'video'} className="d-none">

			<Embed 
				id="embed" 
				src="https://player.vimeo.com/video/558037627?background=1&loop=0&autoplay=0&muted=false" 
				allow="autoplay" 
				frameBorder="0" 
				webkitallowfullscreen='true' 
				mozallowfullscreen='true' 
				allowFullScreen
			/>

			<AnswerCounter id="host-answercounter" className="d-none">
                NA
			</AnswerCounter>

			<BottomContainer id="host-decisions" className="d-none">
				<div id="timer-bar" className="mt-3">
					<div id="timer-bar-container"></div>
					<div id="timer-bar-fill"></div>
				</div>
				<Decisions>
					<Decision id="host-decision-1"> 
						<Indexing>1. </Indexing>
						<span id="decision-1">Antwoord </span>
						<Votes id="stemmen-1" className="d-none">00 </Votes>
					</Decision>
					<Decision id="host-decision-2">
						<Indexing>2. </Indexing>
						<span id="decision-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident, accusantium.</span>
						<Votes id="stemmen-2" className="d-none">00</Votes>
					</Decision>
					<Decision id="host-decision-3">
						<Indexing>3. </Indexing>
						<span id="decision-3">Antwoord</span>
						<Votes id="stemmen-3" className="d-none">00</Votes>
					</Decision>
					<Decision id="host-decision-4">
						<Indexing>4. </Indexing>
						<span id="decision-4">Antwoord</span>
						<Votes id="stemmen-4" className="d-none">00</Votes>
					</Decision>
				</Decisions>
				{/* <div className="row decision-row">
					<div id="host-decision-1" className="col-6 host-decision h-100">
						<div className="d-flex flex-row h-100">
							<div className="mr-2 my-auto host-decision-nr">1</div>
							<div id="decision-1" className="flex-grow-1 my-auto">Antwoord</div>
							<div id="stemmen-1" className="d-none stemmen px-3 my-auto">10</div>
						</div>
					</div>
					<div id="host-decision-3" className="col-6 host-decision h-100">
						<div className="d-flex flex-row h-100">
							<div className="mr-2 my-auto host-decision-nr">3</div>
							<div id="decision-3" className="flex-grow-1 my-auto">Antwoord</div>
							<div id="stemmen-3" className="d-none stemmen px-3 my-auto">10</div>
						</div>
					</div>

				</div>
				<div className="row h-50 decision-row">
					<div id="host-decision-2" className="col-6 host-decision h-100">
						<div className="d-flex flex-row h-100">
							<div className="mr-2 my-auto host-decision-nr">2</div>
							<div id="decision-2" className="flex-grow-1 my-auto">Antwoord</div>
							<div id="stemmen-2" className="d-none stemmen px-3 my-auto">10</div>
						</div>
					</div>

					<div id="host-decision-4" className="col-6 host-decision h-100">
						<div className="d-flex flex-row h-100">
							<div className="mr-2 my-auto host-decision-nr">4</div>
							<div id="decision-4" className="flex-grow-1 my-auto">Antwoord</div>
							<div id="stemmen-4" className="d-none stemmen px-3 my-auto">10</div>
						</div>
					</div>
				</div> */}
			</BottomContainer>

			{/* the video cover prevents users from selecting the iframe, which in turn disabled our input logic */}
			<VideoCover/> 
		</Container>
	);
};

// styled components

const Container = styled.div`
	position: absolute;
	inset:0;
    width: 100%;
    height: 100%;

    background-color: black;

    overflow: hidden;

	z-index: 2;
`;

const BottomContainer = styled.div`
	background-color: purple;
	padding-inline: 50px;
	padding-block: 10px;

	display: flex;
	flex-direction: column;
`;

const Embed = styled.iframe`
    height: 100%;
    width: 100%;
`;

const AnswerCounter = styled.div`
	position: absolute;
    background-color: grey;
    /* font-style: italic; */

    text-transform: uppercase;
    font-family: 'Graphik';
    font-size: 4em;
    color: white;
    /* text-shadow: 0.10em 0 #262626; */

    right:0;
    top: 100px;

	padding-inline: 20px;
	padding-bottom: 10px;
`;

const VideoCover = styled.div`
	position: absolute;
    width: 100%;
    height: 100%;
    left:0;
    top:0;
`;

const Decisions = styled.div`
	/* background-color: hotpink; */
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	flex-grow: 1;
	gap: 10px;
	margin-top: 10px;

	font-family: 'Graphik';
	color: ${Theme.colors.neutralLightest};
	/* font-size: 3rem; */

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		font-size: 3vw;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		font-size: 3vw;
	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){   
		font-size: 4vw;
	}
`;

const Decision = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;

	.decision-lost {
		background-color: hotpink;
	}
`;

const Votes = styled.span`
	margin-left: auto;
	margin-right: 10px;
	background-color: ${Theme.colors.neutralDarker};
	padding-bottom: 4px;
	padding-inline: 20px;
`;

const Indexing = styled.span`
	color: ${Theme.colors.primary}
`;