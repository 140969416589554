import * as Websockets from './websockets.js';
import * as ScenarioManager from './ScenarioManager.js';
import * as VideoManager from './VideoManager.js';
import * as State from './State.js';

let ws;
let roomcode;
let roomState;
let currentDecisions;

let initialized = false;

export function Initialize(){

	// prevent initialization to happen multiple times
	if(initialized) return;
	initialized = true;
	
	Websockets.Host(HostCallback);
	UpdatePlayerAmount();
	document.getElementById('host-start-btn').addEventListener('click', function(){OpenPC('pc-select'); document.getElementById('intro-sound').play();});
	document.getElementById('outro-btn').addEventListener('click', function(){OpenPC('pc-end'); WSCall({'type': 'end'}); document.getElementById('outro-sound').play();});
	document.getElementById('videoend-return-btn').addEventListener('click', function(){OpenPC('pc-select');});
	document.addEventListener('scenario_end', () => {OpenPC('pc-videoend');});

	for(let i = 0; i < 9; i++){
		document.getElementById('host-scene-btn-' + i).addEventListener('click', function(){
			VideoManager.SetSound(true); 
			ScenarioManager.StartScene(i);
		});

	}
	
	roomState = 'pong';
}

// Websockets

function HostCallback(data){
	ws = data.ws;
	roomcode = data.roomcode;
	document.getElementById('host-roomcode').innerHTML = roomcode;
	document.getElementById('host-roomcode-2').innerHTML = roomcode;

	ws.onmessage = ReceiveMessage; // Receive messages

	CheckConnections();
}

function ReceiveMessage(message){
	let data = JSON.parse(message.data);
	switch(data.type){
	case 'ping': 
		ws.send(JSON.stringify({
			'type' : 'pong',
			'state' : roomState,
			'data' : currentDecisions,
		}));
		playerAmount++;
		connectionsFound++;
		UpdatePlayerAmount();
		break;
	case 'answer':
		PlayerAnswer(data.answer);
		break;
	case 'conn-pong':
		connectionsFound++;
		break;
	}
}

export function StartDecisions(decisions){
	currentDecisions = decisions;
	WSCall({'type': 'decisions-start', 'data': decisions});
}

export function WSCall(message){
	setTimeout(() => {
		roomState = message.type;
		ws.send(JSON.stringify(message));
	}, 100);

}

// Data

let playerAmount = 0;

function UpdatePlayerAmount(){
	document.getElementById('host-playeramount').innerHTML = playerAmount;
	document.getElementById('host-answercounter').innerHTML = playerAmount - playersAnswered;
}

// Decisions

let playersAnswered = 0;
let votes = {
	1 : 0,
	2 : 0,
	3 : 0,
	4 : 0,
};
let lastwinner = 1;
   
function PlayerAnswer(answer){
	playersAnswered++;
	votes[answer] += 1;
	document.getElementById('host-answercounter').innerHTML = playerAmount - playersAnswered;

	if(playersAnswered >= playerAmount){
		VotingFinished();
	}
}

export function VotingFinished(){
	document.getElementById('host-answercounter').classList.add('d-none');
	State.ChangeState('continue');
	ScenarioManager.EndTimer();
	WSCall({'type' : 'decisions-end'});
	let winner = 1;
	let winningValue = 0;
	for(const [key, value] of Object.entries(votes)){
		if(value > winningValue){
			winner = key;
			winningValue = value;
		}
	}
	lastwinner = winner;
	ShowResults(winner);
}

function ShowResults(winner){
	for(let i = 1; i < 5; i++){
		if(i != winner){
			document.getElementById('host-decision-' + i).classList.add('decision-lost');
		}
		else{
			document.getElementById('host-decision-' + i).classList.remove('decision-lost');
		}
		let resultElem = document.getElementById('stemmen-' + i);
		resultElem.classList.remove('d-none');
		resultElem.innerHTML = votes[i];
	}
	//votes = {1:0,2:0,3:0,4:0};
	//playersAnswered = 0;
}

document.addEventListener('state_continue', ContinueAfterResults);

function ContinueAfterResults(){
	HideResults();
	ScenarioManager.MakeDecision(lastwinner);
}

export function OverwriteResults(i){
	ShowResults(i);
	lastwinner = i;
}

function HideResults(){
	for(let i = 1; i < 5; i++){
		document.getElementById('host-decision-' + i).classList.remove('decision-lost');
		let resultElem = document.getElementById('stemmen-' + i);
		resultElem.classList.add('d-none');
	}
	votes = {1:0,2:0,3:0,4:0};
	playersAnswered = 0;
}

// page content

let activePCTag = 'pc-host';


function OpenPC(tag){
	document.getElementById(activePCTag).style.display = 'none';
	document.getElementById(tag).style.display = '';
	// document.getElementById(activePCTag).classList.add('d-none');
	// document.getElementById(tag).classList.remove('d-none');
	activePCTag = tag;

	if(activePCTag === 'pc-land'){
		document.getElementById('footer-roomcode').style.display = 'none';
		document.getElementById('footer-playercount').style.display = '';
	}
	else{
		document.getElementById('footer-roomcode').style.display = '';
		document.getElementById('footer-playercount').style.display = 'none';
	}
}


// Check connections to update player count
let connectionsFound = 0;

function CheckConnections(){
	playerAmount = connectionsFound;
	UpdatePlayerAmount();
	connectionsFound = 0;

	let response = {
		'type' : 'conn-ping',
	};
	ws.send(JSON.stringify(response));

	//update every second
	setTimeout(function(){CheckConnections();}, 1000);
}
