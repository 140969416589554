import styled from 'styled-components';
import { Button } from '../components/Button';
import { Title } from '../components/Title';
import { useTranslation } from 'react-i18next';

export const Playing = () => {
	const {t} = useTranslation();
	return(
		<Container id={'pc-playing'} className="d-none">
			<h2 id={'join-status'}></h2>
			<Decisions id="player-decisions" className="d-none">
				<Title id='player-decisions-title'>{t('pages.playing.choose')}</Title>
				{[...Array(4)].map((value, index) => 
					<Decision key={`player-decision-${index + 1}`} id={`player-decision-${index + 1}-btn`}><span>{index + 1}. </span><span style={{color: 'white'}} id={`player-decision-${index + 1}-text`}>Optie 1 is best wel lang poepope</span></Decision>
				)}
			</Decisions>
		</Container>
	);
};

// styled components

const Container = styled.div`
	flex-grow:1;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
`;

const Decisions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-bottom: 50px;
	width: 100%;
`;

const Decision = styled(Button)`
	text-transform: none;
	font-family: 'Graphik';
	font-size: 1.4rem;

	&:disabled{
		filter: grayscale(100%);
	}
`;