import styled from 'styled-components';
import Theme from '../../style/theme';
import afslogo from '../../assets/images/afslogo.png';
import { useTranslation } from 'react-i18next';

const path = window.location.pathname;

export const Footer = () => {
	const {t} = useTranslation();
	return (
		<Container>
			{/* show content based on path (host or player?) */}
			{path === '/host' ?
				<>
					<Item id={'footer-playercount'}>
						{t('pages.footer.connected')}
						<span id="host-playeramount">0</span>
					</Item>
					<Item id={'footer-roomcode'} style={{display: 'none'}}>
					room code:
						<span id="host-roomcode-2">000000</span>
					</Item>

					<AFSLogo/>
				</>
				:
				<AFSLogo style={{marginInline: 'auto'}}/>
			}
		</Container>
	);
};

// styled components

const Container = styled.div`
	background-color: ${Theme.colors.neutralDarkest};
	height: 90px;
	flex-shrink: 0;

	display: flex;
	align-items: center;

	@media (min-width: ${Theme.responsive.media.xs}){
		padding-inline: ${Theme.responsive.whitespace.xs}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		padding-inline: ${Theme.responsive.whitespace.sm}px;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		padding-inline: ${Theme.responsive.whitespace.lg}px;
	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		padding-inline: ${Theme.responsive.whitespace.xl /2}px;
	}
`;

const Item = styled.div`
	text-transform: uppercase;
	font-family: 'graphik';
	padding-bottom: 4px;
	font-size: 2.3rem;
	color: ${Theme.colors.neutralLightest};

	& > span {
		color: ${Theme.colors.primary};
		margin-left: 5px;
		text-transform: none;
	}
`;

const RoomCode = styled.div`
	
`;

const AFSLogo =  styled.div`
	height: 100%;
	aspect-ratio: 2.3/1;
	background-image: url(${afslogo});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin-left:auto;
`;

