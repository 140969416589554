import styled from 'styled-components';
import { Title } from '../components/Title';
import { Button } from '../components/Button';
import Theme from '../../style/theme';
import { useTranslation } from 'react-i18next';

export const Join = () => {
	const {t} = useTranslation();
	return (
		<div id={'pc-join'} style={{display: 'flex', flexDirection: 'column'}}>
			<Title>{t('pages.join.title')}</Title>
			<p>
				{t('pages.join.description')}
			</p>
			<form className="" id="form-join">
				<StyledInput className="form-control" type="text" placeholder="vul hier de code in ..."/><br/>
			</form>
			<p style={{color: Theme.colors.primary}} id="connection-notification"></p>
			<Button id={'form-join-submit'} style={{width: '100%'}}>{t('pages.join.enter')}</Button>
			{/* <Button id={'form-join-submit'} style={{width: '100%', marginTop: 'auto', marginBottom: '1em'}} onClick={() =>   window.open('https://nl.research.net/r/Fairplay2023', '_blank').focus()}>{t('pages.join.list')}</Button>	 */}
		</div>
	);
};

// styled components

const StyledInput = styled.input`
	width: 100%;
	border-radius: 0;
	border:0;

	font-family: 'ZillaSlab';
	font-size: 1rem;

	padding: .5rem;
`;